<template>
	<v-form v-bind="$attrs" v-on="$listeners">
		<slot></slot>
	</v-form>
</template>

<script>
export default {
	name: 'WForm'
}
</script>
